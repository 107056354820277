import { useContext } from "react";
import { Contex } from "./MyContex";

const Product = () => {
  const { product } = useContext(Contex);
  return (
    <div className="container">
      {/* Section Search  */}
      <div className="row justify-content-center mb-5">
        <div className="col-8">
          <h2 className="text-center tagline mb-4 mt-2">All Product</h2>
          <div className="input-group">
            <form action="" className="d-flex w-100">
              <input type="text " className=" searchInput " placeholder="search something..." />
              <button className="primaryBtnStyle primaryBtn br">Search</button>
            </form>
          </div>
        </div>
      </div>
      {/* Section Product  */}
      <div className="row">
        {product.map((p) => {
          return (
            <div className="col-3 my-3"  key={p.id}>
              <div className="product-card">
                <div className="image-wrapper">
                  <img className="w-100" src={p.image} alt={"image" + p.brand} />
                </div>
                <div>
                  <span className="title-category mt-2">{p.category} </span>
                  <span className="title-brand">{p.brand} </span>
                  <span className="title-description mt-2 mb-3">{p.description} </span>
                </div>
                <div className="price-wrapper d-flex justify-content-between align-items-center">
                  <span className="title-price">{p.price} </span>
                  <button className="sm-btnPrimary">
                    buy <i className="bi bi-cart-plus"></i>
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Product;
