import { useContext } from "react";
import { Contex } from "./MyContex";

const CategoryCard = () => {
  const { category } = useContext(Contex);
  return (
    <div className="container py-5">
      <div className="title-wrapper">
        <h6>Product Category</h6>
      </div>

      <div className="row my-4 d-md-flex justify-content-center">
        {category.map((c) => {
          return (
            <div className="col-lg-2 col-md-3 col-4 " key={c.id}>
              <div className="card-category w-100 text-center position-relative my-2 my-md-3 ">
                <img className="w-100" src={c.image}  alt="Skin Care Image" />
                <div className="category-label fw-semibold position-absolute bottom-0 w-100">
                  {c.description}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryCard;
