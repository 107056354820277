import "../src/App.css";
import 'bootstrap-icons/font/bootstrap-icons.css'
// import BannerPromotion from "./components/BannerPromotion";
// import CategoryCard from "./components/CategoryCard";
import HomePage from "./components/HomePage";
// import { Provider } from "./components/MyContex";

const App = () => {
  return (
    <>
      <HomePage />
    </>
  );
};

export default App;
